import React, { useContext } from 'react';

import { globalContext } from '../GlobalContext';


import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

// @ts-ignore
import AllServicesImage from "../assets/AllServices.png";

import { Link } from "gatsby";

import Typography from '@material-ui/core/Typography';

/**
 * 'Home' page for users that are authenticated. Can navigate to most features (favorites, profile preferences, swiper, etc) of the app from this page
 * @param {*} props 
 * @author BrianCastor
 */
export default function AuthenticatedHomePage(props) {
  const context = useContext(globalContext);

  //Redirect to / if user is not signed in
  if (!context.isRegistered()) {
    context.navigateSafe('/');
    return (null);
  }

  return (
    <div style={{ height: '100%' }}>
      <Container maxWidth="md" style={{ paddingTop: '15px' }}>
        <h2 style={{ color: 'white' }}>Greetings, {context.currentUser.FirstName}</h2>

        <Link to="/swipe" style={{ color: 'black', textDecoration: 'none' }}>
          <Paper>
            <Grid container>
              <Grid item xs={12} sm={5} style={{ padding: '10px' }}>
                <div style={{ width: '100%', height: '250px', backgroundImage: `url(${AllServicesImage})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }}>
                </div>
              </Grid>
              <Grid item xs={12} sm={7} style={{ border: '1px solid rgb(230,230,230)', position:'relative'}}>
                <div style={{ padding: '20px', marginBottom:'40px', zIndex:2}}>
                  <Typography gutterBottom variant="h6" component="h3">
                    We've built a list of verified services based on your preferences.
                  </Typography>
                  <Button variant="contained" color="primary" style={{ marginTop: '15px', zIndex:2 }}>View Your Matches</Button>
                </div>
                {/*Cool graphic*/}
                <div style={{position:'absolute', bottom:0, left:0, right:0, height:'80px', zIndex:1}}> 
                  <svg id="bg-svg" viewBox="0 0 1440 600" preserveAspectRatio="none" height="80px" width="100%">
                    <defs>
                      <linearGradient id="gradient">
                        <stop offset="0%" stopColor="#c1272d"></stop>
                        <stop offset="100%" stopColor="rgb(251, 176, 59)"></stop>
                      </linearGradient>
                    </defs>
                    <path d="M 0,600 L 0,600 0,600  L 1440,0 1440,600 Z" stroke="none" strokeWidth="0" fill="url(#gradient)"></path>
                  </svg>
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Link>

        <Link to="/favorites" style={{ color: 'black', textDecoration: 'none' }}>
          <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px', marginTop: '20px', textAlign: 'center' }}>
            <span>View your Favorites</span>
          </Paper>
        </Link>
        <Link to="/profile" style={{ color: 'black', textDecoration: 'none' }}>
          <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px', textAlign: 'center' }}>
            <span>Update Profile / Preferences</span>
          </Paper>
        </Link>
      </Container>
    </div>
  );
}