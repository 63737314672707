import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import AuthenticatedHomePage from "../components/authenticatedHomePage"

const HomePage = (data) => (
  <Layout>
    <Seo title="Home" />
    <AuthenticatedHomePage />
  </Layout>
)

export default HomePage;